<template>
  <div class="settings-card">
    <div class="type-tabs">
      <div
        class="settings-tab"
        :class="{ active: tab === Tab.Mic }"
        @click="tab = Tab.Mic"
      >
        <i class="fas fa-microphone mr-1" />
        Mic
      </div>

      <div
        class="settings-tab"
        :class="{ active: tab === Tab.Sound }"
        @click="tab = Tab.Sound"
      >
        <i class="fas fa-volume-up mr-1" />
        Sound
      </div>

      <div
        class="settings-tab"
        :class="{ active: tab === Tab.Camera }"
        @click="tab = Tab.Camera"
      >
        <i class="fas fa-video mr-1" />
        Camera
      </div>
    </div>

    <div class="settings-card__body">
      <template v-if="tab === Tab.Mic">
        <h3 class="settings-card__body-title">Microphone</h3>
        <Microphone :user="user" />
      </template>
      <template v-else-if="tab === Tab.Sound">
        <h3 class="settings-card__body-title">Sound</h3>
        <Headphones />
      </template>
      <template v-else-if="tab === Tab.Camera && hasCamera">
        <h3 class="settings-card__body-title">Camera</h3>
        <Camera
          :camera="camera"
          :cameras="cameras"
          :image="!!file"
          @ready="videoRef = $event"
          @onCameraChanged="reset"
        />
      </template>
    </div>

    <div class="settings-card__controls">
      <RtbButton :disabled="working" @click="done">
        <v-progress-circular
          v-if="uploading"
          indeterminate
          size="32"
          class="mr-2"
        />
        Done
      </RtbButton>

      <RtbButton
        v-if="tab === Tab.Camera && hasCamera"
        :disabled="working"
        @click="takePhoto"
        class="ml-3"
      >
        Take Photo
        <VueCountdown
          v-if="counting"
          :time="3000"
          @end="captureImage"
          v-slot="{ totalSeconds }"
        >
          &nbsp; {{ totalSeconds }}
        </VueCountdown>
      </RtbButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import uniqid from "uniqid"
import { uploadMedia as upload } from "@/services/storage.service"
import { db } from "@/firebase"
import VueCountdown from "@chenfengyuan/vue-countdown"
import { RtbButton } from "@/components/ui"
import { ActionTypes as TwilioActionTypes } from "@/store/TwilioModule"

import useCanvasImage from "@/components/GroupTeams/Common/AudioVideoSettings/useCanvasImage"
import Microphone from "./Microphone"
import Headphones from "./Headphones"
import Camera from "./Camera"

const Tab = {
  Sound: "sound",
  Video: "video",
  Mic: "mic"
}

export default {
  name: "AudioVideoSettings",
  components: {
    Microphone,
    Headphones,
    Camera,
    VueCountdown,
    RtbButton
  },

  setup() {
    const { imageFromElement } = useCanvasImage()
    return { imageFromElement }
  },

  data() {
    return {
      Tab,
      tab: Tab.Camera,
      counting: false,
      file: null,
      videoRef: null,
      working: false
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "token"]),
    camera() {
      return this.$store.state.twilio.camera
    },
    cameras() {
      return this.$store.state.twilio.cameras
    },
    hasCamera() {
      return Boolean(this.camera && this.cameras?.length)
    },
    uploading() {
      return this.working && this.file
    }
  },
  mounted() {
    this.$store.dispatch("twilio/tryToEnumerateDevices")
  },
  watch: {
    tab() {
      this.reset()
    }
  },
  methods: {
    ...mapActions("twilio", [TwilioActionTypes.UPDATE_LOCAL_VIDEO_TRACK]),
    takePhoto() {
      this.file = null
      this.counting = true
    },
    reset() {
      this.file = null
      this.counting = false
    },
    async captureImage() {
      this.counting = false
      this.file = await this.imageFromElement(this.videoRef)
    },
    async done() {
      if (this.file) {
        this.working = true
        try {
          const blob = this.file
          const fileName = `gamephotos/${uniqid()}-${this.user?.firstname}.jpg`
          const url = await upload({
            fileName,
            blob,
            token: this.token
          })
          await db.ref(`org/1/users/${this.user?.id}/image`).set(url)
        } catch (e) {
          console.error(e.message)
        }
        this.working = false
      }

      await this[TwilioActionTypes.UPDATE_LOCAL_VIDEO_TRACK]()
      this.$emit("onDone")
    }
  }
}
</script>

<style lang="scss">
.settings-card {
  height: 610px;
  padding: 30px 35px 30px;
  color: #fff;
  background-color: #393b42;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  &__controls {
    margin-top: auto;
    display: flex;
    justify-content: center;
    padding-top: 8px;
  }

  .v-input input {
    max-height: 44px;
    height: 44px;
  }

  .v-text-field .v-input__append-inner {
    margin-top: 10px;
  }

  .v-select__selections .v-select__selection {
    padding-left: 15px;
    font-size: 18px;
    font-weight: 400;
  }

  .v-input__slot {
    background-color: #e8e9eb;
  }

  &__body {
    padding-top: 32px;
  }

  &__body-title {
    font-size: 24px;
    font-weight: 700;
  }
}

.type-tabs {
  width: 100%;
  max-width: 335px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 4px;
  background-color: #292932;
  border-radius: 4px;
}

.settings-tab {
  width: 33%;
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in-out;
  font-size: 18px;
  line-height: 18px;

  cursor: pointer;

  &.active {
    background-color: #8680ff;
    border-radius: 4px;
  }
}
</style>
