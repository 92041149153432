var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "test-audio", on: { click: _vm.play } },
    [
      _vm.hasSetSinkId
        ? _c("v-select", {
            staticClass:
              "v-select--theme--default video-check-card__select mt-3",
            attrs: { items: _vm.devices, "hide-details": "", dense: "" },
            model: {
              value: _vm.deviceId,
              callback: function ($$v) {
                _vm.deviceId = $$v
              },
              expression: "deviceId",
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "test-audio__btn mt-5",
          class: _vm.playing ? "test-audio__btn--active" : null,
        },
        [
          _c("i", { staticClass: "fas fa-volume-up mr-2" }),
          _c("span", [_vm._v("Test Sound")]),
        ]
      ),
      _c("audio", {
        ref: "audio",
        attrs: { src: _vm.src },
        on: { ended: _vm.stop },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }