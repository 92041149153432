var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "settings-card" }, [
    _c("div", { staticClass: "type-tabs" }, [
      _c(
        "div",
        {
          staticClass: "settings-tab",
          class: { active: _vm.tab === _vm.Tab.Mic },
          on: {
            click: function ($event) {
              _vm.tab = _vm.Tab.Mic
            },
          },
        },
        [_c("i", { staticClass: "fas fa-microphone mr-1" }), _vm._v(" Mic ")]
      ),
      _c(
        "div",
        {
          staticClass: "settings-tab",
          class: { active: _vm.tab === _vm.Tab.Sound },
          on: {
            click: function ($event) {
              _vm.tab = _vm.Tab.Sound
            },
          },
        },
        [_c("i", { staticClass: "fas fa-volume-up mr-1" }), _vm._v(" Sound ")]
      ),
      _c(
        "div",
        {
          staticClass: "settings-tab",
          class: { active: _vm.tab === _vm.Tab.Camera },
          on: {
            click: function ($event) {
              _vm.tab = _vm.Tab.Camera
            },
          },
        },
        [_c("i", { staticClass: "fas fa-video mr-1" }), _vm._v(" Camera ")]
      ),
    ]),
    _c(
      "div",
      { staticClass: "settings-card__body" },
      [
        _vm.tab === _vm.Tab.Mic
          ? [
              _c("h3", { staticClass: "settings-card__body-title" }, [
                _vm._v("Microphone"),
              ]),
              _c("Microphone", { attrs: { user: _vm.user } }),
            ]
          : _vm.tab === _vm.Tab.Sound
          ? [
              _c("h3", { staticClass: "settings-card__body-title" }, [
                _vm._v("Sound"),
              ]),
              _c("Headphones"),
            ]
          : _vm.tab === _vm.Tab.Camera && _vm.hasCamera
          ? [
              _c("h3", { staticClass: "settings-card__body-title" }, [
                _vm._v("Camera"),
              ]),
              _c("Camera", {
                attrs: {
                  camera: _vm.camera,
                  cameras: _vm.cameras,
                  image: !!_vm.file,
                },
                on: {
                  ready: function ($event) {
                    _vm.videoRef = $event
                  },
                  onCameraChanged: _vm.reset,
                },
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "settings-card__controls" },
      [
        _c(
          "RtbButton",
          { attrs: { disabled: _vm.working }, on: { click: _vm.done } },
          [
            _vm.uploading
              ? _c("v-progress-circular", {
                  staticClass: "mr-2",
                  attrs: { indeterminate: "", size: "32" },
                })
              : _vm._e(),
            _vm._v(" Done "),
          ],
          1
        ),
        _vm.tab === _vm.Tab.Camera && _vm.hasCamera
          ? _c(
              "RtbButton",
              {
                staticClass: "ml-3",
                attrs: { disabled: _vm.working },
                on: { click: _vm.takePhoto },
              },
              [
                _vm._v(" Take Photo "),
                _vm.counting
                  ? _c("VueCountdown", {
                      attrs: { time: 3000 },
                      on: { end: _vm.captureImage },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var totalSeconds = ref.totalSeconds
                              return [
                                _vm._v("   " + _vm._s(totalSeconds) + " "),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1583461425
                      ),
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }