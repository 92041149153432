<template>
  <div class="test-audio" @click="play">
    <v-select
      v-if="hasSetSinkId"
      v-model="deviceId"
      :items="devices"
      hide-details
      dense
      class="v-select--theme--default video-check-card__select mt-3"
    />

    <div
      class="test-audio__btn mt-5"
      :class="playing ? 'test-audio__btn--active' : null"
    >
      <i class="fas fa-volume-up mr-2" />
      <span>Test Sound</span>
    </div>
    <!-- TODO -->
    <audio ref="audio" @ended="stop" :src="src" />
  </div>
</template>

<script>
const TEST_AUDIO_URL = require("@/assets/celebration.mp3")

export default {
  name: "Headphones",
  data() {
    return {
      src: TEST_AUDIO_URL,
      playing: false,
      hasSetSinkId: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.hasSetSinkId = Boolean(this.$refs.audio?.setSinkId)
    })
  },
  computed: {
    devices() {
      return this.$store.state.twilio.speakers.map((device, index) => ({
        value: device.deviceId,
        text: device.label === "" ? `Device ${index + 1}` : device.label
      }))
    },
    deviceId: {
      get() {
        return this.$store.state.twilio.speaker
      },
      set(value) {
        this.$store.commit("twilio/SET_SPEAKER", value)
        this.$refs.audio.setSinkId && this.$refs.audio.setSinkId(value)
      }
    }
  },
  methods: {
    play() {
      if (this.playing) {
        if (this.$refs.audio.setSinkId && this.$store.state.twilio.speaker) {
          this.$refs.audio.setSinkId(this.$store.state.twilio.speaker)
        }

        this.$refs.audio
        this.$refs.audio.pause()
        this.stop()
      } else {
        this.playedOnce = true
        if (this.$refs.audio) {
          if (this.$refs.audio.paused) {
            this.$refs.audio.volume = 0.22
            this.$refs.audio.play()
            this.playing = true
          }
        }
      }
    },
    stop() {
      this.playing = false
    }
  }
}
</script>

<style lang="scss">
.test-audio {
  display: flex;
  flex-direction: column;

  &__btn {
    cursor: pointer;
    font-size: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;

    i {
      font-size: 30px;
    }

    &--active {
      color: $primary_accent_color;
      text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
